import React from 'react'
import './Health.scss'

const featureList = [
	{
		name: '医事咨询',
		desc: '极速问诊+名医咨询。匹配优质的医疗资源，全国50+家优秀三甲医院推荐，公立或民营体检机构的选择与体检项目方案设计等；1000+知名专家就医协助预约及就医陪同。',
	},
	{
		name: '特色服务',
		desc: '心血管疾病日常管理、消化道疾病的体外快速检测、三高人群慢病日常管理',
	},
	{
		name: '健康宣导',
		desc: '为企业提供线上、线下的健康培训',
	},
	{
		name: '健康教育检测项目',
		desc: '利用一体化检测设备和智能硬件为企业客户搭建服务企业员工的多体征检测中心，可实现近20项体征数据检测。',
	},
]

const Health = () => {
	return (
		<div id='health'>
			<div className='health-title'>企业健康角</div>
			<div className='health-desc'>
				公司以一体化的健康体检机、体外快速检测试剂等产品为依托，在企业内部建设健康角。实现企业员工的疾病风险筛查分析、慢病
			</div>
			<div className='health-desc'>
				日常管理、日常健康记录、健康数据档案管理、线上医事咨询，就医协助预约等功能。利用一体化检测设备和智能硬件为企业客户
			</div>
			<div className='health-desc'>
				搭建服务企业员工的多体征检测中心，可实现近20项体征数据检测。
			</div>
			<div className='features'>
				{featureList.map((item) => (
					<div className='item' key={item.name}>
						<div className='title'>{item.name}</div>
						<div className='desc'>{item.desc}</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Health
