import React from 'react'
import './Service.scss'
import detail1 from '../asset/detail1.png'
import icon1 from '../asset/icon-1.png'
import icon2 from '../asset/icon-2.png'
import icon3 from '../asset/icon-3.png'
import icon4 from '../asset/icon-4.png'

const featureList = [
	{
		name: '挖掘企业痛点定位风险源',
		icon: 'icon-qiye',
	},
	{
		name: '预防工作期间突发恶性健康事件',
		icon: 'icon-qiyeOA',
	},
	{
		name: '提高企业用工效能 节省员工福利成本',
		icon: 'icon-lipin',
	},
]

const detailList = [
	{
		name: '搭建企业专属健康数据库',
		desc: '通过健康角健康数据、基础信息、就诊病例等，为企业员工进行风险评估，定位风险类型与程度，使用户清晰知晓健康状况与所面临的健康风险。',
	},
	{
		name: '分层筛查-疾病预防',
		desc: '公司依托与权威三甲医院及知名专家建立庞大医疗资源数据库，确保满足您就医需求的同时，精准为您预约合适的看诊专家，提高诊疗效率，降低误诊风险。',
	},
	{
		name: '慢病管理',
		desc: '协调医疗资源实现及时院内治疗，通过监测和干预院外日常管理，搭建了检测、治疗、监测、干预的疾病闭环的智慧管理平台。',
	},
]

const detail2List = [
	{
		name: '重疾风险筛查',
		desc: ['全员筛查，定位风险种类，', '明确企业当下是否存在重疾风险'],
	},
	{
		name: '风险分级筛查',
		desc: ['根据上层筛查结果，定位风险人员，按其风险等级进行后续筛查'],
	},
	{
		name: '问题分类干预',
		desc: ['提供就医建议，安排就医协助', '根据结果进行健康干预'],
	},
	{
		name: '恶性异常人员',
	},
	{
		name: '潜在恶性异常人员',
	},
]

const detail3List = [
	{
		icon: icon1,
		name: '检测',
		desc: ['定期医院检测', '影像检测', '生化检测'],
	},
	{
		icon: icon2,
		name: '监测',
		desc: ['日常健康数据记录', '智能设备监测', '监测跟踪预警'],
	},
	{
		icon: icon3,
		name: '治疗',
		desc: ['定期诊疗与复诊', '病理性症状的医疗措施'],
	},
	{
		icon: icon4,
		name: '干预',
		desc: ['专家咨询', '健康管理师指导', '用药及提醒'],
	},
]

const Service = () => {
	return (
		<div className='section' id='service'>
			<div className='section-title'>企业服务</div>
			<div>
				<div className='service-desc'>
					评估企业员工因压力过大猝死等工伤风险、预防工作期间突发恶性健康事件，
				</div>
				<div className='service-desc'>提高企业用工效能、节省员工福利成本。</div>
			</div>
			<div className='features'>
				{featureList.map((item) => (
					<div className='feature-block' key={item.name}>
						<div className='icon-block'>
							<svg className='icon' aria-hidden='true'>
								<use xlinkHref={`#${item.icon}`} color='#EFB200'></use>
							</svg>
						</div>
						<div className='desc'>{item.name}</div>
					</div>
				))}
			</div>
			<div className='details'>
				{detailList.map((item, index) => (
					<div className='detail' key={item.name}>
						<svg className='icon' aria-hidden='true'>
							<use xlinkHref={`#icon-shuzi${index + 1}`} color='#EFB200'></use>
						</svg>
						<div>
							<div className='title'>{item.name}</div>
							<div className='desc'>{item.desc}</div>
						</div>
						{index === 0 && <img src={detail1} className='pic' />}
						{index === 1 && (
							<div className='bg2'>
								<div className='detail2-list'>
									{detail2List.slice(0, 3).map((item) => (
										<div className='item' key={item.name}>
											<div className='item-title'>{item.name}</div>
											{
												<div className='item-desc'>
													{item.desc.map((content) => (
														<div className='item-desc-line' key={content}>
															{content}
														</div>
													))}
												</div>
											}
										</div>
									))}
								</div>
								<div className='detail2-list2'>
									{detail2List.slice(3).map((item) => (
										<div className='item' key={item.name}>
											{item.name}
										</div>
									))}
								</div>
							</div>
						)}
						{index === 2 && (
							<div className='flex'>
								<div className='rect'></div>
								<div className='area'>
									<div className='detail3-list'>
										{detail3List.map((item) => (
											<div className='item' key={item.name}>
												<img src={item.icon} className='pic' />
												<div className='title'>{item.name}</div>
												{item.desc.map((desc) => (
													<div className='desc' key={desc}>
														{desc}
													</div>
												))}
											</div>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	)
}

export default Service
