import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import Service from './components/Service'
import Health from './components/Health'
import Helper from './components/Helper'
import './App.scss'
import logo from './asset/logo-white.png'
import mobile from './asset/mobile.png'
import friend from './asset/friend.png'

function throttle(callback, limit) {
	var waiting = false // Initially, we're not waiting
	return function () {
		// We return a throttled function
		if (!waiting) {
			// If we're not waiting
			callback.apply(this, arguments) // Execute users function
			waiting = true // Prevent future invocations
			setTimeout(function () {
				// After a period of time
				waiting = false // And allow future invocations
			}, limit)
		}
	}
}

function isElementInViewport(el) {
	var rect = el.getBoundingClientRect()

	return (
		rect.top >
		(window.innerHeight || document.documentElement.clientHeight) - rect.height
	)
}
const joinList = [
	{
		icon: 'icon-weizhi',
		desc: '总部：北京市朝阳区百子湾路东朝时代广场东区1号楼2层203室',
	},
	{
		icon: 'icon-weizhi',
		desc: '武汉：武汉市东湖高新技术开发区国际企业中心宏宇楼301-2',
	},
	{ icon: 'icon-weizhi', desc: '威海：山东省威海市荣成风尚国际925室' },
	{ icon: 'icon-dianhua', desc: '电话：010-87766912' },
	{ icon: 'icon-youjian', desc: '邮箱：ritchieqi@lunixtech.com' },
]

function App() {
	const ref = useRef(null)
	const [height, setHeight] = useState(window.innerHeight)
	const [scrollTop, setScrollTop] = useState(window.innerHeight / 2)
	const [visiable, setVisiable] = useState(false)

	function onscroll() {
		setScrollTop(
			window.innerHeight / 2 + document.documentElement.scrollTop / 2
		)
		if (!visiable && isElementInViewport(ref.current)) {
			setVisiable(true)
		}
	}

	useEffect(() => {
		window.onresize = function () {
			setHeight(window.innerHeight)
		}

		window.onscroll = throttle(onscroll)
	}, [])

	return (
		<div className='App'>
			<header className='App-header'>
				<img src={logo} className='App-logo' alt='logo' />
				<div className='nav-list'>
					<a className='link' href='#about'>
						关于公司
					</a>
					<a className='link' href='#service'>
						企业服务
					</a>
					<a className='link' href='#health'>
						企业健康角
					</a>
					<a className='link' href='#helper'>
						就医协助
					</a>
					<a className='link' href='#friend'>
						合作伙伴
					</a>
					<a className='link' href='#join'>
						加入我们
					</a>
				</div>
			</header>
			<div className='content'>
				<div id='company' style={{ height }}>
					<div className='pic' style={{ top: scrollTop + 'px' }} />
				</div>
				<div className='section' id='about'>
					<div className='about-area'>
						<div className='about-title'>关于公司</div>
						<p className='about-desc'>
							廘尊科技（北京）有限公司（简称：廘尊科技)，成立于2018年8月，注册资本1500万元，总部位于北京，在威海设有研发中心。
						</p>
						<p className='about-desc'>
							公司创始人及执行团队均来自清华紫光集团、航天科技集团、新浪集团等大型企业，博士、硕士、海归人员占比超过70％。
						</p>
						<p className='about-desc'>
							公司研发完成了具有完全自主知识产权的“滴答一检“健康管理平台（Dida
							WSM
							Ver2.0），立足于“互联网+健康”细分领域，通过研发尖端科技和整合高端医疗资源，致力于解决大型央企、国有企业职工健康管理问题，为企业客户提供“健康角”建设、健康科普、就医协助和新冠疫情应急等一系列专属服务。
						</p>
						<p className='about-desc'>
							廘尊科技秉承“科技创新、追求卓越”的企业理念，快速实现产品上市和市场化步伐。2021年，廘尊投资北京普瑞驰生物科技有限责任公司，在消化道快速检测、新冠抗原检测试剂盒领域取得重大进展，与“京东”签署合作协议，实现了消化道系列快速检测产品的居家检测和销售。{' '}
						</p>
						<p className='about-desc'>
							目前，廘尊科技的主要客户涵盖：政府、大型央企、国有企业、养老机构、高等院校和科研机构、三甲医院等。
						</p>
					</div>
					<img
						src={mobile}
						className={classNames('mobile-pic', { bounce: visiable })}
						ref={ref}
					/>
				</div>
				{/** 企业服务 */}
				<Service />
				{/** 企业健康角 */}
				<Health />
				{/** 就医协助 */}
				<Helper />
				<div className='section' id='friend'>
					<div className='section-title'>合作伙伴</div>
					<img className='pic' src={friend} />
				</div>
				<div className='section' id='join'>
					<div className='section-title'>加入我们</div>
					<div className='join-desc'>
						因公司业务发展需要，广纳贤才，虚位以待。招聘前端java开发工程师、研发、BD商务总监、市场经理等多个职位，诚邀贤才加入，请联系我们
					</div>
					<div className='join-list'>
						{joinList.map((item) => (
							<div className='item' key={item.desc}>
								<svg className='icon' aria-hidden='true'>
									<use xlinkHref={`#${item.icon}`} color='#fff'></use>
								</svg>
								<div className='desc'>{item.desc}</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default App
