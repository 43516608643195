import React from 'react'
import './Helper.scss'
import helper1 from '../asset/helper-1.png'
import helper2 from '../asset/helper-2.png'
import helper3 from '../asset/helper-3.png'

import f1 from '../asset/f-1.png'
import f2 from '../asset/f-2.png'
import f3 from '../asset/f-3.png'
import f4 from '../asset/f-4.png'
import f5 from '../asset/f-5.png'
import f6 from '../asset/f-6.png'
const featureList = [
	{
		pic: helper1,
		name: '诊前精准分诊',
		desc: '诊前通过就医咨询可精准分诊，快速帮您预约合适的专家。',
	},
	{
		pic: helper2,
		name: '诊中全程陪同',
		desc: '全程陪同，排队取号、协助缴费、取药送药，节省您的就医等候时间，就医不超过90分钟，单例服务平均为您节省3.5小时。',
	},
	{
		pic: helper3,
		name: '诊后无忧服务',
		desc: '专业的诊后健康关怀及康复跟踪； 复诊提醒，防止您遗忘复查耽误病情。',
	},
]

const list = [
	{
		name: '专家会诊',
		pic: f1,
	},
	{
		name: '紧急就医',
		pic: f2,
	},
	{
		name: '康复咨询',
		pic: f3,
	},
	{
		name: '手术协助',
		pic: f4,
	},
	{
		name: '检查协助',
		pic: f5,
	},
	{
		name: '住院协助',
		pic: f6,
	},
]

const Helper = () => {
	return (
		<div id='helper'>
			<div className='helper-title'>就医协助</div>
			<div className='features'>
				{featureList.map((item) => (
					<div className='item' key={item.name}>
						<img className='pic' src={item.pic} />
						<div className='title'>{item.name}</div>
						<div className='desc'>{item.desc}</div>
					</div>
				))}
			</div>
			<div className='list'>
				{list.map((item) => (
					<div className='item' key={item.name}>
						<img className='pic' src={item.pic} />
						<div className='title'>{item.name}</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Helper
